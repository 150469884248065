import React from 'react';

const PlanitLogoFilled = () => {
  return (
    <div>
      <svg
        width="310"
        height="144"
        viewBox="0 0 414 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.824 79.688C31.9573 76.36 34.9013 73.672 38.656 71.624C42.4107 69.576 46.8053 68.552 51.84 68.552C57.728 68.552 63.0613 70.0453 67.84 73.032C72.6187 76.0187 76.3733 80.2853 79.104 85.832C81.92 91.3787 83.328 97.8213 83.328 105.16C83.328 112.499 81.92 118.984 79.104 124.616C76.3733 130.163 72.6187 134.472 67.84 137.544C63.0613 140.531 57.728 142.024 51.84 142.024C46.8907 142.024 42.496 141 38.656 138.952C34.9013 136.904 31.9573 134.259 29.824 131.016V175.048H7.936V69.576H29.824V79.688ZM61.056 105.16C61.056 99.6987 59.52 95.432 56.448 92.36C53.4613 89.2027 49.7493 87.624 45.312 87.624C40.96 87.624 37.248 89.2027 34.176 92.36C31.1893 95.5173 29.696 99.8267 29.696 105.288C29.696 110.749 31.1893 115.059 34.176 118.216C37.248 121.373 40.96 122.952 45.312 122.952C49.664 122.952 53.376 121.373 56.448 118.216C59.52 114.973 61.056 110.621 61.056 105.16Z"
          fill="white"
        />
        <path d="M121.819 46.28V141H99.931V46.28H121.819Z" fill="white" />
        <path
          d="M138.449 105.16C138.449 97.8213 139.814 91.3787 142.545 85.832C145.361 80.2853 149.158 76.0187 153.937 73.032C158.716 70.0453 164.049 68.552 169.937 68.552C174.972 68.552 179.366 69.576 183.121 71.624C186.961 73.672 189.905 76.36 191.953 79.688V69.576H213.841V141H191.953V130.888C189.82 134.216 186.833 136.904 182.993 138.952C179.238 141 174.844 142.024 169.809 142.024C164.006 142.024 158.716 140.531 153.937 137.544C149.158 134.472 145.361 130.163 142.545 124.616C139.814 118.984 138.449 112.499 138.449 105.16ZM191.953 105.288C191.953 99.8267 190.417 95.5173 187.345 92.36C184.358 89.2027 180.689 87.624 176.337 87.624C171.985 87.624 168.273 89.2027 165.201 92.36C162.214 95.432 160.721 99.6987 160.721 105.16C160.721 110.621 162.214 114.973 165.201 118.216C168.273 121.373 171.985 122.952 176.337 122.952C180.689 122.952 184.358 121.373 187.345 118.216C190.417 115.059 191.953 110.749 191.953 105.288Z"
          fill="white"
        />
        <path
          d="M278.316 68.808C286.679 68.808 293.335 71.5387 298.284 77C303.319 82.376 305.836 89.8 305.836 99.272V141H284.076V102.216C284.076 97.4373 282.839 93.7253 280.364 91.08C277.889 88.4347 274.561 87.112 270.38 87.112C266.199 87.112 262.871 88.4347 260.396 91.08C257.921 93.7253 256.684 97.4373 256.684 102.216V141H234.796V69.576H256.684V79.048C258.903 75.8907 261.889 73.416 265.644 71.624C269.399 69.7467 273.623 68.808 278.316 68.808Z"
          fill="white"
        />
        <path d="M348.824 69.576V141H326.936V69.576H348.824Z" fill="#9AF2FF" />
        <path
          d="M409.614 122.44V141H398.478C390.542 141 384.355 139.08 379.918 135.24C375.481 131.315 373.262 124.957 373.262 116.168V87.752H364.558V69.576H373.262V52.168H395.15V69.576H409.486V87.752H395.15V116.424C395.15 118.557 395.662 120.093 396.686 121.032C397.71 121.971 399.417 122.44 401.806 122.44H409.614Z"
          fill="#9AF2FF"
        />
        <path
          d="M337.5 36.9375C334.645 36.9375 331.853 37.7329 329.479 39.2232C327.105 40.7135 325.254 42.8316 324.161 45.3099C323.069 47.7881 322.783 50.515 323.34 53.1459C323.897 55.7768 325.272 58.1934 327.291 60.0901C329.31 61.9869 331.883 63.2786 334.683 63.8019C337.484 64.3252 340.387 64.0566 343.025 63.0301C345.663 62.0036 347.918 60.2653 349.504 58.0349C351.091 55.8046 351.938 53.1824 351.938 50.5C351.933 46.9042 350.411 43.4569 347.704 40.9143C344.997 38.3718 341.328 36.9416 337.5 36.9375V36.9375ZM325.146 50.8817L326.526 51.3136L328.219 53.6995V54.9425C328.219 55.1994 328.327 55.4458 328.521 55.6275L331.313 58.25V60.5522C329.495 59.5644 327.976 58.1584 326.897 56.4666C325.818 54.7747 325.215 52.8528 325.146 50.8817V50.8817ZM337.5 62.125C336.608 62.1234 335.719 62.0309 334.848 61.8492L335.438 60.1875L337.299 55.8169C337.361 55.671 337.384 55.5131 337.367 55.3568C337.349 55.2005 337.292 55.0505 337.199 54.9197L335.744 52.8689C335.65 52.7362 335.522 52.6274 335.372 52.5521C335.223 52.4769 335.055 52.4375 334.886 52.4375H329.802L328.514 50.6234L330.708 48.5625H332.344V50.5H334.406V47.8511L338.395 41.2931L336.605 40.3319L335.723 41.7812H332.896L331.776 40.2033C333.279 39.4579 334.932 39.0151 336.627 38.9031C338.323 38.7912 340.025 39.0126 341.625 39.5531V42.75C341.625 43.0069 341.734 43.2533 341.927 43.435C342.12 43.6167 342.383 43.7188 342.656 43.7188H344.167C344.337 43.7188 344.504 43.6794 344.653 43.6041C344.803 43.5289 344.931 43.4201 345.025 43.2874L345.929 42.0131C347.107 43.0449 348.06 44.2823 348.735 45.6562H344.533C344.295 45.6562 344.064 45.7338 343.879 45.8758C343.695 46.0178 343.569 46.2154 343.522 46.435L342.777 50.7661C342.742 50.9698 342.777 51.1787 342.877 51.3624C342.978 51.546 343.138 51.6948 343.334 51.7871L346.781 53.4062L347.488 57.3352C346.343 58.8171 344.842 60.0236 343.106 60.856C341.37 61.6885 339.449 62.1233 337.5 62.125V62.125Z"
          fill="#9AF2FF"
        />
      </svg>
    </div>
  );
};

export default PlanitLogoFilled;
